//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    size: {
      type: String,
      required: true
    },
    ecLevel: {
      type: String,
      required: true
    },
    codeStatus: {
      type: String,
      required: true
    }
  },
  methods: {
    changeSize(e) {
      this.$emit('onChangeSize', e.target.value);
    },
    changeEcLevel(e) {
      this.$emit('onChangeEcLevel', e.target.value);
    }
  }
};
