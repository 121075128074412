var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: 24 } },
    [
      _c(
        "a-col",
        { attrs: { xs: 24, lg: 12 } },
        [
          _c("span", { staticClass: "pr-md" }, [
            _vm._v(_vm._s(_vm.$t("Code size")) + ":")
          ]),
          _c(
            "a-radio-group",
            { attrs: { value: _vm.size }, on: { change: _vm.changeSize } },
            [
              _c("a-radio-button", { attrs: { value: "0" } }, [_vm._v(" XS ")]),
              _c("a-radio-button", { attrs: { value: "1" } }, [_vm._v(" S ")]),
              _c("a-radio-button", { attrs: { value: "2" } }, [_vm._v(" M ")]),
              _c("a-radio-button", { attrs: { value: "3" } }, [_vm._v(" L ")]),
              _c("a-radio-button", { attrs: { value: "4" } }, [_vm._v(" XL ")])
            ],
            1
          ),
          _c(
            "a-tooltip",
            { attrs: { placement: "top" } },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticClass: "mb-xs" }, [
                  _vm._v(_vm._s(_vm.$t("Code size")))
                ]),
                _c("div", [_vm._v(_vm._s(_vm.$t("code.codeSize.XS")))]),
                _c("div", [_vm._v(_vm._s(_vm.$t("code.codeSize.S")))]),
                _c("div", [_vm._v(_vm._s(_vm.$t("code.codeSize.M")))]),
                _c("div", [_vm._v(_vm._s(_vm.$t("code.codeSize.L")))]),
                _c("div", [_vm._v(_vm._s(_vm.$t("code.codeSize.XL")))])
              ]),
              _c("a-icon", {
                staticClass: "ml-xs",
                staticStyle: { "font-size": "22px" },
                attrs: { type: "question-circle" }
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { xs: 24, lg: 12 } },
        [
          _c("span", { staticClass: "pr-md" }, [
            _vm._v(_vm._s(_vm.$t("EC error level")) + ":")
          ]),
          _c(
            "a-radio-group",
            {
              attrs: { value: _vm.ecLevel },
              on: { change: _vm.changeEcLevel }
            },
            [
              _c("a-radio-button", { attrs: { value: "0" } }, [_vm._v(" L ")]),
              _c("a-radio-button", { attrs: { value: "1" } }, [_vm._v(" M ")]),
              _c("a-radio-button", { attrs: { value: "2" } }, [_vm._v(" H ")])
            ],
            1
          ),
          _c(
            "a-tooltip",
            { attrs: { placement: "top" } },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticClass: "mb-xs" }, [
                  _vm._v(_vm._s(_vm.$t("EC error level")))
                ]),
                _c("div", [_vm._v(_vm._s(_vm.$t("code.errorLevel.L")))]),
                _c("div", [_vm._v(_vm._s(_vm.$t("code.errorLevel.M")))]),
                _c("div", [_vm._v(_vm._s(_vm.$t("code.errorLevel.H")))])
              ]),
              _c("a-icon", {
                staticClass: "ml-xs",
                staticStyle: { "font-size": "22px" },
                attrs: { type: "question-circle" }
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }